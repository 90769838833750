import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import TopicBookCards from "../../components/topicComponents/TopicBookCards";

const BookIndex = ({ data }) => {
  return (
    <Layout pageTitle="Books">
      <h1 className="text-2xl font-bold">Topics</h1>
      <div className="topic-page-booklist flex flex-col gap-4 mt-6 md:mt-12">
        {data.allAirtable.nodes &&
          data.allAirtable.nodes.map(book => (
            <div key={book.id}>
              <TopicBookCards data={book.data} />
            </div>
          ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(filter: { data: { bookTitle: { ne: null } } }) {
      nodes {
        data {
          bookTitle
          Topics__from_topic_tags_
          authors
          bookCover {
            localFiles {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        id
      }
    }
  }
`;

export default BookIndex;
