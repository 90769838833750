import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { slugifyWithRegex, sliceToNextSpace } from "../../utilities/utilities";

const TopicBookCards = ({ data }) => {
  const descToShow = data.descriptionRewritten
    ? sliceToNextSpace(data.descriptionRewritten, 300)
    : data.description
    ? sliceToNextSpace(data.description, 300)
    : "";

  return (
    <Link to={`/books/${slugifyWithRegex(data.bookTitle)}`}>
      <div className="card card-side bg-base-300 shadow-lg w-full items-center">
        <div className="px-1 py-1 rounded-md h-full basis-1/3 grow-0 shrink-0">
          <GatsbyImage
            style={{
              height: "192px",
              width: "100%",
            }}
            imgStyle={{ borderRadius: "0.5rem" }}
            objectFit="contain"
            loading="lazy"
            image={data.bookCover.localFiles[0].childImageSharp.gatsbyImageData}
            alt={data.bookTitle}
          />
        </div>
        <div className="card-body">
          <div>
            <h3 className="text-base md:text-xl card-title">
              {data.bookTitle}
            </h3>
            <p className="text-sm md:text-base">
              {data.authors.map(author => author).join(", ")}
            </p>
          </div>
          <div className="flex flex-wrap flex-row gap-2 ">
            {data.Topics__from_topic_tags_ &&
              data.Topics__from_topic_tags_.map(topic => (
                <div
                  key={topic}
                  className="badge badge-accent badge-outline text-xs md:text-sm"
                >
                  {topic}
                </div>
              ))}
          </div>
          <div className="hidden md:block">
            <p className="card-body-description">
              {descToShow} <span className="italic">...Read More</span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TopicBookCards;
